import Swiper from 'swiper'

/**
 *  カルーセルクラス
 *  @version 2018/06/10
 */
export default new class Carousel {

  /**
   *  カルーセルを適用する
   *  @version 2018/06/10
   */
  start = () => {

    if (document.querySelector('.c-carousel')) {

      // カルーセルを初期化する
      this.carousel = new Swiper('.c-carousel', {
        wrapperClass: 'c-carousel__list',
        slideClass: 'c-carousel__item',
        loop: true,
        pagination: {
          el: '.c-carousel__nav',
        },
        navigation: {
          nextEl: '.c-carousel__next',
          prevEl: '.c-carousel__prev',
        },
      });
    }
  };
}
