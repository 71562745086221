import Initializer from 'lib/initializer'
import Xhr         from 'lib/xhr'

/**
 *  無限スクロールクラス
 *  @version 2018/06/10
 */
export default new class InfiniteScroll {

  /**
   *  コンストラクタ
   *  @version 2018/06/10
   */
  constructor() {

    this.NAV_SELECTOR       = 'nav.pagination';
    this.LAST_PAGE_SELECTOR = 'nav.pagination > .current > .last';

    this.observed_element = null;
    this.last_page = 1;
    this.observer = null;

    window.InfiniteScroll = this;
  }

  /**
   *  無限スクロールを適用する
   *  @version 2018/06/10
   */
  start = () => {

    this.cancel();

    this.observer = new IntersectionObserver(this._loadNextPage);

    this.observed_element = document.getElementById('js-infinite');

    // 最終ページ番号を取得
    this.last_page = Number(this.observed_element.querySelector(this.LAST_PAGE_SELECTOR).innerText);

    // 無限スクロール時に隠すコンテンツ
    Array.from(document.querySelectorAll('.js-infinite-hidden'), element => {
      element.style.display = 'none';
    });

    // viewportの監視を開始
    this.observer.observe(this.observed_element);
  }

  /**
   *  スクロール監視
   *  @version 2018/06/10
   */
  _getCurrentPage = url => {

    let page = 1;
    const params = url.split('?')[1];
    if (!params) return page;

    params.split('&').forEach(param => {
      if (param.match(/page=/)) page = Number(param.replace('page=', ''));
    });
    return page;
  }

  /**
   *  無限スクロールをキャンセルする
   *  @version 2018/06/10
   */
  cancel = () => {

    if (this.observed_element) {
      this.observer.unobserve(this.observed_element);
      this.observed_element = null;
    }
  }

  /**
   *  次のページを取得する
   *  @version 2018/06/10
   */
  _loadNextPage = () => {

    this.cancel();

    let next_url = null;
    let current_page = 1;
    let next_page = 2;
    let canonical_dom = null;

    document.getElementsByTagName('link').forEach(link => {

      if (link.rel == 'canonical') {

        current_page = this._getCurrentPage(link.href);
        next_page = current_page + 1;

      } else if (link.rel == 'next') {

        next_url = link.href;
      }
    });

    // 次のページが存在する場合
    if (next_url) {

      // 次のページを取得する
      Xhr.request.get(next_url, { responseType: 'text' }).then(response =>{

        const fragment = document.createElement('div');
        fragment.innerHTML = response.data;
        const articles = fragment.querySelector('.js-fragment-selector');

        // 取得したページ内容を反映
        document.getElementById('js-infinite-contents').appendChild(articles);
        // ページナビゲーションを更新
        document.querySelector(this.NAV_SELECTOR).innerHTML = fragment.querySelector(this.NAV_SELECTOR).innerHTML;

        // metaタグを更新
        document.getElementsByTagName('link').forEach(link => {

          if (link.rel == 'canonical') {

            canonical_dom = link;
            link.href = next_url;
          } else if (link.rel == 'next') {

            if (this.last_page <= next_page) {
              link.remove();
              this.cancel();
            } else {
              link.href = next_url.replace(/page=[0-9]+/, 'page=' + (next_page + 1));
              this.start();
            }
          } else if (link.rel == 'prev') {

            link.href = window.location.href;
          }
        });

        // 最初のページの場合はmetaタグ(prev)を追加
        if (current_page == 1 && canonical_dom) {
          let prev_dom = document.createElement('link');
          prev_dom.rel = 'prev';
          prev_dom.href = window.location.href;
          prev_dom.appendAfter(canonical_dom);
        }

        // 現在のURLを更新し、turbolinkにcacheする
        history.pushState({turbolinks: {restorationIdentifier: window.Turbolinks.controller.restorationIdentifier}}, null, next_url);
        window.Turbolinks.controller.cache.put(next_url, window.Turbolinks.controller.view.getSnapshot());

        // 画像の遅延読込開始
        Initializer.lazy();
      });
    }
  }
}
