//##############################################################################
// Utilities
//##############################################################################

/**
 *  汎用クラス
 *  @version 2018/06/10
 */
export default new class Utilities {

  /**
   *  コンストラクタ
   *  @version 2018/06/10
   */
  constructor() {

    // DOMの機能を拡張
    Element.prototype.appendAfter = function (element) {
      element.parentNode.insertBefore(this, element.nextSibling);
    }, false;
    Element.prototype.appendBefore = function (element) {
      element.parentNode.insertBefore(this, element);
    }, false;

    // HTMLCollectionの機能を拡張
    if (typeof HTMLCollection.prototype.forEach === 'undefined') HTMLCollection.prototype.forEach = Array.prototype.forEach;
  }

  /**
   *  日付のフォーマット
   *  @version 2018/06/10
   */
  dateFormat = str => {

    const date = new Date(str.replace(/-/g, '/'));
    return `${date.getFullYear()}年${date.getMonth() + 1}月${date.getDate()}日`;
  }

  /**
   *  数値のカンマ区切り
   *  @version 2018/06/10
   */
  numberWithDelimiter = num => {

    return String(num).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
  }

  /**
   *  startからendまでのrangeを取得
   *  @version 2018/06/10
   */
  range = (start, end) => {

    return Array.from({length: (end + 1 - start)}, (v, k) => k + start );
  };

  /**
   *  htmlタグを除去
   *  @version 2018/06/10
   */
  trimHtmlTags = text => {

    if (!text || text == '') return '';
    return text.replace(/<("[^"]*"|'[^']*'|[^'">])*>/g,'');
  };

  /**
   *  文字列をクリップボードにコピーする
   *  @version 2018/06/10
   */
  copyToClipboard = text => {

    let temp = document.createElement('div');

    temp.appendChild(document.createElement('pre')).textContent = text;
    temp.style.position = 'fixed';
    temp.style.left = '-100%';

    document.body.appendChild(temp);
    document.getSelection().selectAllChildren(temp);

    const result = document.execCommand('copy');

    document.body.removeChild(temp);
    return result;
  }
}
